import React from "react";
import { graphql } from "gatsby";
import { PageLayout, Typography } from "@components";
import * as styles from "./CMSJobTemplate.module.scss";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import Button from "@components/Button_new/Button";

type CMSJobTemplateProps = {
  data: {
    cmsJob: {
      id: string;
      title: string;
      field_location: string;
      relationships: {
        field_department: {
          name: string;
        };
        field_job_type: {
          name: string;
        };
      };
      body: {
        summary: string;
        processed: string;
      };
    };
  };
};
export const query = graphql`
  query ($JobId: String!) {
    cmsJob: nodeJob(id: { eq: $JobId }) {
      id
      title
      field_location
      relationships {
        field_department {
          name
        }
        field_job_type {
          name
        }
      }
      field_location
      body {
        summary
        processed
      }
    }
  }
`;

export default function CMSJobTemplate({
  data, // this prop will be injected by the GraphQL query below.
}: CMSJobTemplateProps) {
  const { title, relationships, field_location: location, body } = data.cmsJob;

  return (
    <PageLayout 
      seoOptions={{
        type: "BLOG",
        data: {
          title: title,
          description: body.summary,
          article: true,
        }
      }} 
      defaultHeader="light"
    >
      <BackgroundFadeContainer type="solidLightPink" withHeaderPadding>
        <div className={styles.jobTemplateContainer}>
          <Header
            title={title}
            department={relationships.field_department.name}
            location={location}
            type={relationships.field_job_type.name}
          />
          <div
            className={styles.jobDetailsContainer}
            dangerouslySetInnerHTML={{ __html: body.processed }}
          />
        </div>
      </BackgroundFadeContainer>
    </PageLayout>
  );
}

type HeaderProps = {
  title: string;
  department: string;
  location: string;
  type: string;
};
function Header({ title, department, location, type }: HeaderProps) {
  const SubField = (field: { label: string; value: string }) => (
    <div className={styles.subField}>
      <Typography variant="CAPTION" color="cloudy-blue">
        {field.label}
      </Typography>
      <Typography className={styles.subFieldValue}>{field.value}</Typography>
    </div>
  );
  return (
    <div className={styles.header}>
      <Typography
        className={styles.department}
        color="medium-pink"
        variant="CAPTION"
      >
        {department}
      </Typography>
      <Typography variant="HEADER1">{title}</Typography>
      <div className={styles.subFieldsContainer}>
        <SubField label={"Type"} value={type} />
        <SubField label={"Location"} value={location} />
      </div>
      <Button
        className={styles.applyButton}
        to={"mailto:careers@genemod.net"}
        target="_blank"
      >
        Apply now
      </Button>
    </div>
  );
}
