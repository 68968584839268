// extracted by mini-css-extract-plugin
export var BODY = "CMSJobTemplate-module--BODY--BurMM";
export var BODY__newLayout = "CMSJobTemplate-module--BODY__newLayout--3LIT0";
export var BUTTON = "CMSJobTemplate-module--BUTTON--Ze3fs";
export var BUTTON__newLayout = "CMSJobTemplate-module--BUTTON__newLayout--B6jm9";
export var CAPTION = "CMSJobTemplate-module--CAPTION--Kf8Dl";
export var CAPTION__newLayout = "CMSJobTemplate-module--CAPTION__newLayout--1HChJ";
export var DESCRIPTION = "CMSJobTemplate-module--DESCRIPTION--c-gWa";
export var DESCRIPTION__newLayout = "CMSJobTemplate-module--DESCRIPTION__newLayout--lAXec";
export var FOOTER = "CMSJobTemplate-module--FOOTER--vgXTm";
export var FOOTERBOLD = "CMSJobTemplate-module--FOOTERBOLD--r2W-a";
export var FOOTER__newLayout = "CMSJobTemplate-module--FOOTER__newLayout--SJdaQ";
export var HEADER1 = "CMSJobTemplate-module--HEADER1--IrNUy";
export var HEADER1__newLayout = "CMSJobTemplate-module--HEADER1__newLayout--Hqp5A";
export var HEADER2 = "CMSJobTemplate-module--HEADER2--Mvd+-";
export var HEADER2__newLayout = "CMSJobTemplate-module--HEADER2__newLayout--ReASC";
export var HEADER3 = "CMSJobTemplate-module--HEADER3--vEB9+";
export var HEADER3__newLayout = "CMSJobTemplate-module--HEADER3__newLayout--iAJYy";
export var HEADER4 = "CMSJobTemplate-module--HEADER4--mz9uC";
export var HEADER4__newLayout = "CMSJobTemplate-module--HEADER4__newLayout--fgy9R";
export var HERO = "CMSJobTemplate-module--HERO--wvQ8v";
export var HERO__newLayout = "CMSJobTemplate-module--HERO__newLayout--IQdfc";
export var QUOTE = "CMSJobTemplate-module--QUOTE--sw6fi";
export var SUBHEADERDESC = "CMSJobTemplate-module--SUBHEADERDESC--XDGxd";
export var SUBHEADERDESCBOLD = "CMSJobTemplate-module--SUBHEADERDESCBOLD--HtKqq";
export var SUBHEADERDESC__newLayout = "CMSJobTemplate-module--SUBHEADERDESC__newLayout--t4MRf";
export var SUBTITLE = "CMSJobTemplate-module--SUBTITLE--LtmX0";
export var SUBTITLE__newLayout = "CMSJobTemplate-module--SUBTITLE__newLayout--9AtyJ";
export var applyButton = "CMSJobTemplate-module--applyButton--oHEy9";
export var center = "CMSJobTemplate-module--center--v+htu";
export var department = "CMSJobTemplate-module--department--f6oyF";
export var header = "CMSJobTemplate-module--header--Td5zG";
export var jobDetailsContainer = "CMSJobTemplate-module--jobDetailsContainer--simdw";
export var jobTemplateContainer = "CMSJobTemplate-module--jobTemplateContainer--aAOPA";
export var limitWidthOnLargeScreens = "CMSJobTemplate-module--limitWidthOnLargeScreens--0TcDE";
export var subField = "CMSJobTemplate-module--subField--TXxl9";
export var subFieldValue = "CMSJobTemplate-module--subFieldValue--bQL9O";
export var subFieldsContainer = "CMSJobTemplate-module--subFieldsContainer--6-FRd";